const data = [
  {
    text: "peterthibeault.com",
    url: "http://peterthibeault.com"
  },
  {
    text: "marksfisher.com",
    url: "http://marksfisher.com"
  },
  {
    text: "fisherillustration.com",
    url: "http://fisherillustration.com"
  },
  {
    text: "particle17.com",
    url: "http://particle17.com"
  },
  {
    text: "ifeelblank.com",
    url: "http://ifeelblank.com"
  },
  {
    text: "yrbrgr.com",
    url: "http://yrbrgr.com"
  },
  {
    text: "github.com/wylie",
    url: "http://github.com/wylie"
  }
];

export { data };